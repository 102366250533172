import http from "../utils/http";

// 应用接口 
export const apiDevice = {
  deviceTree() {
    return http.request({
      url: "/cemp-monitor/device-monitor/device-tree",
      method: "get",
    });
  },
  //分组树
  deviceTreeSpace(params) {
    return http.request({
      url: "/cemp-bms/device-manager/node-tree",
      method: "get",
      params
    });
  },
  //产品树
  deviceTreeProduct(params) {
    return http.request({
      url: "/cemp-bms/device-manager/product-tree",
      method: "get",
      params
    });
  },
  page(data) {
    return http.request({
      url: "/cemp-bms/device-manager/page",
      method: "post",
      data,
    });
  },
  //新增-下拉项目树
  list(data) {
    return http.request({
      url: "/cemp-bms/project/current-user/list",
      method: "get",
      data,
    });
  },
   //新增-下拉项目树
  detail(params) {
    return http.request({
      url: "/cemp-bms/device-monitor/info",
      method: "get",
      params,
    });
  },

};
