<template>
  <div class="history-analysis">
    <div class="history-analysis-left">
      <div class="device-tree">
        <div class="device-tree-header">
          <a-tabs
            v-model:activeKey="activeKey"
            centered
            @change="handleTabChange"
          >
            <a-tab-pane :key="1" tab="产品"></a-tab-pane>
            <a-tab-pane :key="2" tab="分组"></a-tab-pane>
          </a-tabs>
        </div>
        <div class="device-tree-content">
          <div
            style="text-align: center; padding: 50px 0 0"
            v-if="deviceTreeLoading"
          >
            <a-spin :spinning="deviceTreeLoading" tip="加载中..."> </a-spin>
          </div>
          <a-tree
            v-else-if="deviceTree.length"
            :fieldNames="{
              title: 'name',
              key: 'nodeId',
            }"
            :show-icon="activeKey == 1"
            :defaultExpandedKeys="defaultExpandedKeys"
            :checkedKeys="checkedDevices"
            :tree-data="deviceTree"
            @select="checkDeviceTree"
          >
            <template #icon="{ dataRef, expanded }">
              <auth-image
                v-if="dataRef.type == 1"
                :src="dataRef.image"
                size="18px"
              />
              <img
                v-else-if="expanded && dataRef.type == 0"
                src="../../../assets/folder-open.png"
                width="18"
              />
              <img
                v-else-if="dataRef.type == 0"
                src="../../../assets/folder.png"
                width="18"
              />
            </template>
          </a-tree>

          <a-empty v-else :image="Empty.PRESENTED_IMAGE_SIMPLE" />
        </div>
      </div>
    </div>
    <div class="history-analysis-right">
      <div class="history-analysis-search">
        <a-tabs v-model:activeKey="currentTab" @change="tabChange">
          <a-tab-pane key="1" tab="全部"></a-tab-pane>
          <a-tab-pane key="2" tab="通讯正常"></a-tab-pane>
          <a-tab-pane key="3" tab="通讯异常"></a-tab-pane>
        </a-tabs>
        <div class="history-analysis-search-actions">
          <CategorySearch :source="filterSource" @change="search" />
          <a-button type="primary" @click="showModal(null)">手动添加</a-button>
        </div>
      </div>
      <div class="history-analysis-content">
        <basic-table
          ref="tableRef"
          v-bind="tableConfig"
          @page-change="pageChange"
        >
          <template #slotId="{ row }">
            <router-link
              :to="{
                path: `/IoTManage/device/detail/${row.bizDeviceId}`,
                state: { tabName: row.name },
              }"
              >{{ row.bizDeviceId }}</router-link
            >
          </template>
        </basic-table>
      </div>
    </div>
    <DeviceEditModal
      v-if="modalVisible"
      v-model:visible="modalVisible"
      :productId="productId"
      :productName="productName"
      :detail="currentRow"
      @success="editSuccess"
    ></DeviceEditModal>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted } from "vue";
import { Empty, Modal, message } from "ant-design-vue";
import { apiDevice } from "@/api/device";
import { apiProjectDevice } from "@/api/IoT/productManage.js";
import { BasicTable } from "@/components/basic/table";
import CategorySearch from "@/components/business/CategorySearch/categorySearch.vue";
import DeviceEditModal from "./components/DeviceEditModal";
import authImage from '@/components/basic/authImage/index.vue';
const currentTab = ref("1");
const activeKey = ref(1);

const checkedDevices = ref([]);
const deviceTreeLoading = ref(false);
const deviceTree = ref({});

const defaultExpandedKeys = ref([]);
//左侧树(分组、产品)-数据
const initDeviceTree = async (api) => {
  deviceTreeLoading.value = true;
  try {
    const { result } = await apiDevice[api]();
    deviceTree.value = [result];
    defaultExpandedKeys.value = [result.nodeId];
    console.log(1232, result);
  } catch (error) {
    console.log(error);
  }
  deviceTreeLoading.value = false;
};

const filterSource = ref([
  {
    label: "设备名称",
    type: "input",
    bindKey: "name",
    placeholder: "请输入",
  },
]);

initDeviceTree("deviceTreeProduct");
const handleTabChange = (val) => {
  if (val == 1) {
    //产品
    initDeviceTree("deviceTreeProduct");
  } else if (val == 2) {
    //分组
    initDeviceTree("deviceTreeSpace");
  }
  filterData.nodeId2 = undefined;
  filterData.type2 = undefined;
  filterData.nodeId1 = undefined;
  filterData.type1 = undefined;
  tableRef.value.paginationProps.current = 1;
  initTableData();
};
const checkDeviceTree = (checkedKeys, e) => {
  const { node, selected } = e;
  checkedDevices.value = checkedKeys;
  if (selected) {
    if (activeKey.value == 1) {
      //产品
      filterData.nodeId2 = node.nodeId;
      filterData.type2 = node.type;
      filterData.nodeId1 = undefined;
      filterData.type1 = undefined;
    } else {
      //分组
      filterData.nodeId1 = node.nodeId;
      filterData.type1 = node.type;
      filterData.nodeId2 = undefined;
      filterData.type2 = undefined;
    }
  } else {
    filterData.nodeId2 = undefined;
    filterData.type2 = undefined;
    filterData.nodeId1 = undefined;
    filterData.type1 = undefined;
  }
  tableRef.value.paginationProps.current = 1;
  initTableData();
};

const tableRef = ref();
const tableConfig = reactive({
  showIndex: false,
  showSelect: false,
  pagination: true,
  isPageBottom: true,
  remote: false,
  loading: false,
  scroll: {
    scrollToFirstRowOnChange: true,
    y: "calc(100vh - 280px)",
  },
  columns: [
    {
      title: "设备ID",
      type: "customize",
      slotName: "slotId",
      width: 100,
    },
    {
      title: "所属项目",
      type: "text",
      key: "projectName",
      width: 120,
      ellipsis: true,
    },
    {
      title: "设备名称",
      type: "text",
      key: "name",
      width: 120,
    },
    {
      title: "通讯状态",
      type: "text",
      key: "cst",
      width: 100,
      formatter(val) {
        return val == 1 ? "在线" : "离线";
      },
    },
    {
      title: "设备类型",
      type: "text",
      key: "categoryName",
      width: 100,
    },
    {
      title: "所属产品",
      type: "text",
      key: "productName",
      width: 100,
    },
    {
      title: "设备位置",
      type: "text",
      key: "locationDesc",
      width: 100,
    },
  ],
  action: {
    title: "操作",
    width: 90,
    fixed: "right",
    items: [
      {
        type: "icon",
        icon: "FormOutlined",
        iconTip: "修改",
        onClick: (row) => {
          showModal(row);
        },
      },
      {
        type: "icon",
        icon: "DeleteOutlined",
        iconTip: "删除",
        onClick: (row) => {
          Modal.confirm({
            title: "提示",
            content: "是否删除该设备？",
            okType: "danger",
            onOk: () => {
              return new Promise((resolve, reject) => {
                apiProjectDevice
                  .delete(row.id)
                  .then(async () => {
                    message.success("删除成功");
                    initTableData();
                    resolve();
                  })
                  .catch(() => {
                    reject();
                  });
              });
            },
            onCancel() {
              console.log("Cancel");
            },
          });
        },
      },
    ],
  },
});
const initTableData = async () => {
  const { current, pageSize } = tableRef.value.paginationProps;
  const params = {
    pageNo: current,
    pageSize,
    ...filterData,
  };
  tableConfig.loading = true;
  try {
    const res = await apiDevice.page(params);
    const { records, total } = res?.result ?? {};
    tableConfig.loading = false;
    tableRef.value.dataSourceRef = records;
    tableRef.value.paginationProps.total = total;
  } catch (error) {
    tableConfig.loading = false;
    console.log(error);
  }
};
const pageChange = (pageIndex) => {
  tableRef.value.paginationProps.current = pageIndex;
  initTableData();
};
const editSuccess = ()=>{
  initTableData();
}

//新增
const modalVisible = ref(false);
const currentRow = ref(null);
const showModal = (row) => {
  currentRow.value = row;
  modalVisible.value = true;
};

const filterData = reactive({
  cst: undefined,
  name: undefined,
  nodeId1: undefined,
  type1: undefined,
  nodeId2: undefined,
  type2: undefined,
});
// tab切换
const tabChange = (val) => {
  filterData.cst = val == 1 ? undefined : val == 2 ? 1 : 0;
  tableRef.value.paginationProps.current = 1;
  initTableData();
};
// 搜索
const search = (val) => {
  filterData.name = val.name;
  tableRef.value.paginationProps.current = 1;
  initTableData();
};

onMounted(() => {
  initTableData();
});
</script>
<style lang="less" scoped>
.history-analysis {
  display: flex;
  gap: 8px;
  height: 100%;
  &-left {
    width: 260px;
    background: #fff;
    .device-tree {
      display: flex;
      flex-direction: column;
      height: 100%;
      &-header {
        ::v-deep .ant-tabs-nav {
          margin: 0;
        }
        ::v-deep .ant-tabs-tab {
          font-size: 16px;
          line-height: 1;
          padding: 16px 0;
        }
      }
      &-content {
        flex: 1;
        width: 260px;
        padding: 12px 6px;
        overflow: auto;
      }
    }
  }
  &-right {
    display: flex;
    flex-direction: column;
    flex: 1;
    background: #fff;
    min-width: 850px;
  }
  &-search {
    position: relative;
    padding: 0 20px;
    margin-bottom: 12px;
    ::v-deep .ant-tabs-nav {
      margin: 0;
    }
    ::v-deep .ant-tabs-tab {
      font-size: 16px;
      line-height: 1;
      padding: 16px 0;
    }
    &-actions {
      position: absolute;
      right: 20px;
      top: 6px;
      display: flex;
      button {
        margin-left: 20px;
      }
    }
  }
  &-content {
    padding: 0 20px;
  }
}
</style>
