<template>
  <BasicModal
    :title="title"
    width="500px"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="ok"
    @cancel="cancel"
  >
    <BasicForm
      ref="formRef"
      :labelCol="{ span: 5 }"
      :formItemsMap="formItemsMap"
      v-model:formData="formData"
      @change="changeForm"
    ></BasicForm>
  </BasicModal>
</template>

<script setup>
import {
  defineProps,
  computed,
  ref,
  defineEmits,
  reactive,
  onMounted,
  watch,
} from "vue";
import { message } from "ant-design-vue";
import { apiProjectDevice } from "@/api/IoT/productManage";
import { apiSpace } from "@/api/IoT/project.js";
import { apiDevice } from "@/api/device.js";

const props = defineProps({
  detail: {
    type: Object,
    default: null,
  },
  visible: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["change", "update:visible", "success"]);
const formRef = ref(null);

const title = computed(() => `${props.detail ? "修改" : "添加"}设备`);

onMounted(async () => {
  await initFormItems();
  initFormData();
});

const formItemsMap = reactive({
  projectId: {
    label: "所属项目",
    prop: "projectId",
    type: "select",
    options: [],
    fieldNames: {
      label: "name",
      value: "id",
    },
    required: true,
    disabled: props.detail,
    requiredMessage: "请选择",
  },
  productId: {
    label: "所属产品",
    prop: "productId",
    type: "select",
    options: [],
    fieldNames: {
      label: "name",
      value: "productId",
    },
    required: true,
    disabled: props.detail,
    requiredMessage: "请选择",
  },
  name: {
    label: "设备名称",
    prop: "name",
    type: "input",
    required: true,
    requiredMessage: "请输入",
  },
  code: {
    label: "设备编码",
    prop: "code",
    type: "input",
    required: true,
    requiredMessage: "请输入",
  },
  sourceDeviceId: {
    label: "设备外部ID",
    prop: "sourceDeviceId",
    type: "input",
    required: true,
    requiredMessage: "请输入",
  },
  bizAreaId: {
    label: "所属空间",
    prop: "bizAreaId",
    type: "treeSelect",
    options: [],
    fieldNames: {
      label: "spaceName",
      value: "spaceId",
    },
    required: true,
    requiredMessage: "请选择",
  },
  locationDesc: {
    label: "设备位置",
    prop: "locationDesc",
    type: "input",
  },
  deviceDesc: {
    label: "产品描述",
    prop: "deviceDesc",
    type: "textarea",
  },
});
const initFormItems = async () => {
  //formItemsMap.projectId.disabled = !!props.detail;
  apiDevice.list().then((res) => {
    formItemsMap.projectId.options = res.result;
  });
  apiDevice.deviceTreeProduct().then((res) => {
    formItemsMap.productId.options = filterData(res.result.children);
  });
};
const filterData = (source) => {
  let result = [];
  function filterLoop(data) {
    console.log(12, data);
    data.forEach((item) => {
      if (item.type == 2) {
        result.push(item);
      }
      if (Array.isArray(item.children)) {
        filterLoop(item.children);
      }
    });
  }
  filterLoop(source);
  return result;
};

const initSpaceOptions = async () => {
  console.log(22, "haha", formData.projectId);
  if (!formData.projectId) {
    formData.bizAreaId = undefined;
    formItemsMap.bizAreaId.options = [];
    return;
  }
  try {
    const res = await apiSpace.list(formData.projectId);
    formItemsMap.bizAreaId.options = res.result;
  } catch (error) {
    console.log(error);
  }
};
const changeForm = async ({ prop, value }) => {
  if (prop === "projectId") {
    initSpaceOptions();
  }
  if (prop === "productId") {
    apiProjectDevice.parameterList(value).then((res) => {
      formatFormItems(res.result);
    });
  }
};
const formTypeMap = {
  "01": "input",
  "02": "input",
  "03": "input",
  "04": "select",
  "05": "select",
};
let otherAttrList = [];
const formatFormItems = (data) => {
  otherAttrList = data;
  data.forEach((item) => {
    const { functionName, identifier, dataTpe, valueDescription } = item;
    let _item = {
      label: functionName,
      prop: identifier,
      type: formTypeMap[dataTpe],
      options: valueDescription.map((option) => ({
        label: option.value,
        value: option.key,
      })),
    };
    if (dataTpe === "02" || dataTpe === "03") {
      _item.inputType = "number";
    }
    formItemsMap[identifier] = _item;
  });
};
const formData = reactive({
  projectId: undefined,
  productId: "",
  name: "",
  code: "",
  sourceDeviceId: "",
  bizAreaId: undefined,
  locationDesc: "",
  deviceDesc: "",
});
const initFormData = async () => {
  if (!props.detail) return;
  for (const key in formData) {
    if (key === "bizAreaId") {
      formData[key] = Number(props.detail[key]);
    } else if (key !== "productName") {
      formData[key] = props.detail[key];
    }
  }
  apiDevice.detail({ id: props.detail.id }).then((res) => {
    console.log(23, res);
    const { result: detail } = res;
    for (const key in formData) {
      if (key === "bizAreaId") {
        formData[key] = Number(detail[key]);
      } else {
        formData[key] = detail[key];
      }
    }
    detail.deviceParameters.forEach((item) => {
      formData[item.identifier] = item.value;
    });
    apiProjectDevice.parameterList(detail.productId).then((res) => {
      formatFormItems(res.result);
    });
    initSpaceOptions();
  });
};
watch(
  () => props.productName,
  () => {
    formData.productName = props.productName;
  }
);

const confirmLoading = ref(false);
const ok = async () => {
  try {
    await formRef.value.formRef.validateFields();
    let findOne = formItemsMap.productId.options.find(
      (item) => item.productId === formData.productId
    );
    const params = {
      productName: findOne.name,
      ...formData,
      deviceParameters: otherAttrList.map((item) => ({
        identifier: item.identifier,
        value: formData[item.identifier],
        functionName: item.functionName,
      })),
    };
    if (props.detail) {
      // 修改
      params.id = props.detail.id;
      params.bizDeviceId = props.detail.bizDeviceId;
    }
    confirmLoading.value = true;
    await apiProjectDevice[props.detail ? "edit" : "add"](params);
    message.success(`${props.detail ? "修改" : "添加"}成功`);
    emit("update:visible", false);
    emit("success");
    confirmLoading.value = false;
  } catch (errorInfo) {
    confirmLoading.value = false;
    console.log("Failed:", errorInfo);
  }
};
const cancel = () => {
  emit("update:visible", false);
};
</script>

<style lang="less" scoped></style>
